import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, fontWeights, unitConverter as uc } from '../../../styles/base';
import { Heading3 } from '../../atoms/Headings/Headings';
import Link from '../../atoms/Link/Link';
import Text from '../../atoms/Text/Text';

const linkCss = css`
  color: ${colors.accent};
  font-weight: ${fontWeights.semiBold};
  font-size: ${uc('15px')};
  text-decoration: none;
`;

const contactCss = css`
  ${Text},
  ${Heading3} {
    margin-bottom: ${uc('5px')};
  }
`;

const PressContact = ({ pressContact }) => {
  if (!pressContact) {
    return null;
  }
  return (
    <div css={contactCss}>
      <Heading3>{pressContact.title}</Heading3>
      <Text>{pressContact.name}</Text>
      <Text>
        <Link to={`mailto:${pressContact.email}`} css={linkCss}>
          {pressContact.email}
        </Link>
      </Text>
      <Link to={`tel:${pressContact.phone}`} css={linkCss}>
        {pressContact.phone}
      </Link>
    </div>
  );
};

PressContact.propTypes = {
  pressContact: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
};

export default PressContact;
