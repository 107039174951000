const { configForDataset } = require('../../../backend/config/intlConfig');

const sanityJson = require('../../../backend/sanity');

const dateFormat = configForDataset(sanityJson.api.dataset).formats.date;

const truncateText = (text, numberOfCharacters) => {
  if (text) {
    if (text.length <= numberOfCharacters) {
      return text;
    }
    return `${text.substring(0, numberOfCharacters - 1)}...`;
  }
  return '';
};

const truncateTextByWords = (text, numOfWords) => {
  const splitText = text.split(' ');
  if (splitText.length <= numOfWords) {
    return text;
  }
  return `${splitText.slice(0, numOfWords).join(' ')}...`;
};

module.exports = {
  truncateText,
  truncateTextByWords,
  dateFormat,
};
