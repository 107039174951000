import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import Link from '../../atoms/Link/Link';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  unitConverter as uc,
  arrowSymbol,
} from '../../../styles/base';
import { Heading4 } from '../../atoms/Headings/Headings';
import Card from '../../atoms/Card/Card';
import { truncateText } from '../../../utils/textUtils';
import Text from '../../atoms/Text/Text';

const PressReleaseTile = ({ postDate, slug, title }) => {
  const pressTileCardCss = css`
    position: relative;
    width: ${uc('284px')};
    height: ${uc('270px')};
    margin: ${uc('0px 14px 20px 0px')};
    padding: ${uc('30px')};
    overflow: hidden;
    border-radius: ${uc('30px')};
    cursor: pointer;

    @media (${breakpoints.mobile}) {
      width: 100%;
    }
  `;

  const textCss = css`
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.fifteen};
  `;

  const headingCss = css`
    margin-top: ${uc('10px')};
    color: ${colors.primary};
    font-weight: ${fontWeights.semiBold};

    &:hover {
      &::after {
        content: ${arrowSymbol};
        margin-left: ${uc('3px')};
      }
    }
  `;

  return (
    <Link to={slug}>
      <Card css={pressTileCardCss}>
        <Text css={textCss}>{postDate}</Text>
        <Heading4 css={headingCss}>{truncateText(title, 70)}</Heading4>
      </Card>
    </Link>
  );
};

PressReleaseTile.propTypes = {
  postDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default PressReleaseTile;
